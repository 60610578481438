import React, { useEffect, useState } from "react";
import axios from "axios";
import { parse, stringify } from "qs";
import { Form, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Toolbar,
  Typography,
  IconButton,
  CssBaseline,
  TextField,
  CircularProgress,
  Backdrop,
  Modal,
  Switch,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const drawerWidth = 240;

const axiosInstance = axios.create({
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,

  justifyContent: "flex-end",
}));

function Ops(props) {
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [placementSwitch, setPlacementSwitch] = useState(false);
  const [gamClients, setGamClients] = useState([]);

  const [aliasData, setAliasData] = useState({
    client: "",
    optionLabel: "",
    adNameM: "",
    adIdM: "",
    adNameNM: "",
    adIdNM: "",
    adNameCRA: "",
    adIdCRA: "",
    GaPropId: "",
  });

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const addUnit = async () => {
    setOpenModal(false);

    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/ops_add_units_gam`,
      {
        params: {
          client: aliasData["client"],
          optionLabel: aliasData["optionLabel"],
          nameM: aliasData["adNameM"],
          nameNM: aliasData["adNameNM"],
          nameCRA: aliasData["adNameCRA"],
          idM: aliasData["adIdM"],
          idNM: aliasData["adIdNM"],
          idCRA: aliasData["adIdCRA"],
          GA: aliasData["GaPropId"],
          isPlacement: placementSwitch,
        },
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    alert(res.data.data);
    setAliasData({
      adNameM: "",
      adIdM: "",
      adNameNM: "",
      adIdNM: "",
      adNameCRA: "",
      adIdCRA: "",
      GaPropId: "",
    });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setAliasData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = async () => {
    addUnit();
  };

  const getGamClientList = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_GAMDASH_CDN}/gamclients.json`
    );
    setGamClients(res.data);
  };

  useEffect(() => {
    if (!props.opsAdmin) navigate("/login");
    getGamClientList();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            sx={{ mr: 2, pr: "20px", ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Unibots
          </Typography>
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ flexGrow: 1, p: 2, width: "90%" }}>
        {/* to keep h4 below appbar */}
        <DrawerHeader />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingScreen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            pr: 2,
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              // ml: 3,
              pt: 3,
            }}
          >
            Ops Form
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 4, mb: 8 }}
            onClick={() => {
              setOpenModal(!openModal);
              // setModalData(!modalData)
            }}
          >
            Add Units
          </Button>
        </Box>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 1000,
              bgcolor: "background.paper",
              // border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              borderRadius: "12px",
            }}
          >
            <Box
              display="inline-flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 4 }}
            >
              <Box display="inline-flex" alignContent="center">
                <Typography variant="h5" sx={{ mr: 4, width: 180 }}>
                  {!placementSwitch ? "Add Ad Units" : "Add Placements"}
                </Typography>
                <Switch
                  onChange={() => setPlacementSwitch(!placementSwitch)}
                ></Switch>
              </Box>

              <IconButton onClick={() => setOpenModal(false)}>
                <HighlightOffOutlinedIcon fontSize="large" color="disabled" />
              </IconButton>
            </Box>

            <Box display="inline-flex" width="1" sx={{ mb: 4 }}>
              <FormControl>
                <InputLabel id="client">Select Client</InputLabel>
                <Select
                  name="client"
                  label="Client"
                  id="client"
                  sx={{ width: 300, mr: 8 }}
                  value={aliasData.client}
                  onChange={handleFormChange}
                >
                  {gamClients.map((client, index) => (
                    <MenuItem key={index} value={client}>
                      {client}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="optionLabel"
                label="Option Label"
                id="optionLabel"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.optionLabel}
                onChange={handleFormChange}
              ></TextField>
            </Box>
            <Box display="inline-flex" width="1" sx={{ mb: 4 }}>
              <TextField
                name="adNameM"
                label={
                  placementSwitch ? "Placement Name Main" : "Ad Unit Name Main"
                }
                id="adNameM"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.adNameM}
                onChange={handleFormChange}
              ></TextField>
              <TextField
                name="adIdM"
                label={
                  placementSwitch ? "Placement ID Main" : "Ad Unit ID Main"
                }
                id="adIdM"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.adIdM}
                onChange={handleFormChange}
              ></TextField>
            </Box>
            <Box display="inline-flex" width="1" sx={{ mb: 4 }}>
              <TextField
                name="adNameNM"
                label={
                  placementSwitch
                    ? "Placement Name NewMain"
                    : "Ad Unit Name NewMain"
                }
                id="adNameNM"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.adNameNM}
                onChange={handleFormChange}
              ></TextField>
              <TextField
                name="adIdNM"
                label={
                  placementSwitch
                    ? "Placement ID NewMain"
                    : "Ad Unit ID NewMain"
                }
                id="adIdNM"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.adIdNM}
                onChange={handleFormChange}
              ></TextField>
            </Box>
            <Box display="inline-flex" width="1" sx={{ mb: 4 }}>
              <TextField
                name="adNameCRA"
                label={
                  placementSwitch ? "Placement Name CRA" : "Ad Unit Name CRA"
                }
                id="adNameCRA"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.adNameCRA}
                onChange={handleFormChange}
              ></TextField>
              <TextField
                name="adIdCRA"
                label={placementSwitch ? "Placement ID CRA" : "Ad Unit ID CRA"}
                id="adIdCRA"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.adIdCRA}
                onChange={handleFormChange}
              ></TextField>
            </Box>
            <Box display="inline-flex" width="1" sx={{ mb: 4 }}>
              <TextField
                name="GaPropId"
                label="GA Property ID"
                id="GaPropId"
                sx={{ width: 300, mr: 8 }}
                value={aliasData.GaPropId}
                onChange={handleFormChange}
              ></TextField>
              {/* <TextField
                  name="url"
                  label="website"
                  id="url"
                  sx={{ width: 300, mr: 2 }}
                  value={aliasData.url}
                  onChange={handleFormChange}
                ></TextField> */}
            </Box>
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default Ops;
