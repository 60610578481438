import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Ops from "./Screens/Ops";
// import Adops from "./Screens/Adops";
import Login from "./Auth/Login";
import USreport from "./Screens/USreport";
import Props from "./Screens/Props";
import TvSeasons from "./Screens/TvSeasons";
import Fandom from "./Screens/Fandom";
import Thingsinteractive from "./Screens/Thingsinteractive";
import Setn from "./Screens/Setn";
import ZoomBangla from "./Screens/ZoomBangla";
import PinkVilla from "./Screens/PinkVilla";
import Sandesh from "./Screens/Sandesh";
import Corover from "./Screens/Corover";
import Detik from "./Screens/Detik";
import Infomo from "./Screens/Infomo";
import Adgebra from "./Screens/Adgebra";
import Momjunction from "./Screens/Momjunction";
import QuiztwizDashAdmin from "./Screens/QuiztwizDashAdmin";
import Playbuzz from "./Screens/Playbuzz";
import Allplaynews from "./Screens/Allplaynews";
import Jiocinema from "./Screens/Jiocinema";
import Monetiseup from "./Screens/Monetiseup";
import Vuvu from "./Screens/Vuvu";
import Rediads from "./Screens/Rediads";
import AllplaynewsGAM from "./Screens/AllplaynewsGAM";
import InfiniteDev from "./Screens/InfiniteDev";
import Getchannels from "./Screens/GetChannels";
import DefaultNew from "./Screens/DefaultNew";
import Amarujala from "./Screens/Amarujala";
import Bigfourth from "./Screens/Bigfourth";
import Udyogmitra from "./Screens/Udyogmitra";

function App() {
  const [loggedQuizAdmin, setLoggedQuizAdmin] = useState(false);
  const [timezone, setTimezone] = useState("");

  const [logged, setLogged] = useState(false);
  const [loggedAwt, setLoggedAwt] = useState(false);
  const [loggedProps, setLoggedProps] = useState(false);
  const [loggedFandom, setLoggedFandom] = useState(false);
  const [loggedZoomBangla, setLoggedZoomBangla] = useState(false);
  const [loggedDetik, setLoggedDetik] = useState(false);
  const [loggedSetn, setLoggedSetn] = useState(false);
  const [loggedTvseason, setLoggedTvseason] = useState(false);
  const [loggedPinkvilla, setLoggedPinkvilla] = useState(false);
  const [loggedSandesh, setLoggedSandesh] = useState(false);
  const [loggedThingsint, setLoggedThingsint] = useState(false);
  const [loggedCorover, setLoggedCorover] = useState(false);
  const [loggedInfomo, setLoggedInfomo] = useState(false);
  const [loggedAdgebra, setLoggedAdgebra] = useState(false);
  const [loggedMomjunc, setLoggedMomjunc] = useState(false);
  const [loggedPlaybuzz, setLoggedPlaybuzz] = useState(false);
  const [loggedAllplaynews, setLoggedAllplaynews] = useState(false);
  const [loggedJiocinema, setLoggedJiocinema] = useState(false);
  const [loggedMonetiseup, setLoggedMonetiseup] = useState(false);
  const [loggedVuvu, setLoggedVuvu] = useState(false);
  const [loggedRediads, setLoggedRediads] = useState(false);
  const [opsAdmin, setOpsAdmin] = useState(false);
  const [loggedAllplaynewsGAM, setLoggedAllplaynewsGAM] = useState(false);
  const [loggedInfiniteDev, setLoggedInfiniteDev] = useState(false);
  const [loggedGetchannels, setLoggedGetchannels] = useState(false);
  const [loggedDefaultNew, setLoggedDefaultNew] = useState(false);
  const [loggedAmarujala, setLoggedAmarujala] = useState(false);
  const [loggedBigfourth, setLoggedBigfourth] = useState(false);
  const [loggedUdyog, setLoggedUdyog] = useState(false);

  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/quiztwizadmin"
            element={
              <QuiztwizDashAdmin
                loggedQuizAdmin={loggedQuizAdmin}
                client="quizadmin"
              />
            }
          />
          <Route
            exact
            path="/playbuzz"
            element={<Playbuzz loggedPlaybuzz={loggedPlaybuzz} />}
          />
          <Route
            exact
            path="/allplaynews"
            element={<Allplaynews loggedAllplaynews={loggedAllplaynews} />}
          />
          <Route exact path="/opsform" element={<Ops opsAdmin={opsAdmin} />} />
          <Route
            exact
            path="/allwomenstalk"
            element={<USreport loggedAwt={loggedAwt} />}
          />
          <Route
            exact
            path="/detik"
            element={<Detik loggedDetik={loggedDetik} />}
          />
          <Route
            exact
            path="/props"
            element={<Props loggedProps={loggedProps} />}
          />
          <Route
            exact
            path="/corover"
            element={<Corover loggedCorover={loggedCorover} />}
          />
          <Route
            exact
            path="/tvseasonspoilers"
            element={<TvSeasons loggedTvseason={loggedTvseason} />}
          />
          <Route
            exact
            path="/pinkvilla"
            element={<PinkVilla loggedPinkvilla={loggedPinkvilla} />}
          />
          <Route
            exact
            path="/sandesh"
            element={<Sandesh loggedSandesh={loggedSandesh} />}
          />
          <Route
            exact
            path="/zoombangla"
            element={<ZoomBangla loggedZoomBangla={loggedZoomBangla} />}
          />
          <Route
            exact
            path="/setn"
            element={<Setn loggedSetn={loggedSetn} />}
          />
          <Route
            exact
            path="/thingsinteractive"
            element={<Thingsinteractive loggedThingsint={loggedThingsint} />}
          />
          <Route
            exact
            path="/fandom"
            element={<Fandom loggedFandom={loggedFandom} />}
          />
          <Route
            exact
            path="/infomo"
            element={<Infomo loggedInfomo={loggedInfomo} />}
          />
          <Route
            exact
            path="/adgebra"
            element={<Adgebra loggedAdgebra={loggedAdgebra} />}
          />
          <Route
            exact
            path="/momjunction"
            element={<Momjunction loggedMomjunc={loggedMomjunc} />}
          />
          <Route
            exact
            path="/jiocinema"
            element={<Jiocinema loggedJiocinema={loggedJiocinema} />}
          />
          <Route
            exact
            path="/monetiseup"
            element={<Monetiseup loggedMonetiseup={loggedMonetiseup} />}
          />
          <Route
            exact
            path="/vuvutv"
            element={<Vuvu loggedVuvu={loggedVuvu} />}
          />
          <Route
            exact
            path="/rediads"
            element={<Rediads loggedRediads={loggedRediads} />}
          />
          <Route
            exact
            path="/allplaynews_adx"
            element={
              <AllplaynewsGAM loggedAllplaynewsGAM={loggedAllplaynewsGAM} />
            }
          />
          <Route
            exact
            path="/infinitedeveloper"
            element={<InfiniteDev loggedInfiniteDev={loggedInfiniteDev} />}
          />
          <Route
            exact
            path="/getchannels"
            element={<Getchannels loggedGetchannels={loggedGetchannels} />}
          />
          <Route
            exact
            path="/defaultnew"
            element={<DefaultNew loggedDefaultNew={loggedDefaultNew} />}
          />
          <Route
            exact
            path="/amarujala"
            element={<Amarujala loggedAmarujala={loggedAmarujala} />}
          />
          <Route
            exact
            path="/bigfourth"
            element={<Bigfourth loggedBigfourth={loggedBigfourth} />}
          />
          <Route
            exact
            path="/udyogmitra"
            element={<Udyogmitra loggedUdyog={loggedUdyog} />}
          />
          <Route
            exact
            path="/login"
            element={
              <Login
                setTimezone={setTimezone}
                loggedQuizAdmin={loggedQuizAdmin}
                setLoggedQuizAdmin={setLoggedQuizAdmin}
                loggedAwt={loggedAwt}
                setLoggedAwt={setLoggedAwt}
                loggedProps={loggedProps}
                setLoggedProps={setLoggedProps}
                loggedFandom={loggedFandom}
                setLoggedFandom={setLoggedFandom}
                loggedZoomBangla={loggedZoomBangla}
                setLoggedZoomBangla={setLoggedZoomBangla}
                loggedDetik={loggedDetik}
                setLoggedDetik={setLoggedDetik}
                loggedSetn={loggedSetn}
                setLoggedSetn={setLoggedSetn}
                loggedTvseason={loggedTvseason}
                setLoggedTvseason={setLoggedTvseason}
                loggedPinkvilla={loggedPinkvilla}
                setLoggedPinkvilla={setLoggedPinkvilla}
                loggedSandesh={loggedSandesh}
                setLoggedSandesh={setLoggedSandesh}
                loggedThingsint={loggedThingsint}
                setLoggedThingsint={setLoggedThingsint}
                loggedCorover={loggedCorover}
                setLoggedCorover={setLoggedCorover}
                loggedInfomo={loggedInfomo}
                setLoggedInfomo={setLoggedInfomo}
                loggedAdgebra={loggedAdgebra}
                setLoggedAdgebra={setLoggedAdgebra}
                loggedMomjunc={loggedMomjunc}
                setLoggedMomjunc={setLoggedMomjunc}
                loggedPlaybuzz={loggedPlaybuzz}
                setLoggedPlaybuzz={setLoggedPlaybuzz}
                loggedAllplaynews={loggedAllplaynews}
                setLoggedAllplaynews={setLoggedAllplaynews}
                loggedJiocinema={loggedJiocinema}
                setLoggedJiocinema={setLoggedJiocinema}
                loggedMonetiseup={loggedMonetiseup}
                setLoggedMonetiseup={setLoggedMonetiseup}
                loggedVuvu={loggedVuvu}
                setLoggedVuvu={setLoggedVuvu}
                loggedRediads={loggedRediads}
                setLoggedRediads={setLoggedRediads}
                opsAdmin={opsAdmin}
                setOpsAdmin={setOpsAdmin}
                loggedAllplaynewsGAM={loggedAllplaynewsGAM}
                setLoggedAllplaynewsGAM={setLoggedAllplaynewsGAM}
                loggedInfiniteDev={loggedInfiniteDev}
                setLoggedInfiniteDev={setLoggedInfiniteDev}
                loggedGetchannels={loggedGetchannels}
                setLoggedGetchannels={setLoggedGetchannels}
                loggedDefaultNew={loggedDefaultNew}
                setLoggedDefaultNew={setLoggedDefaultNew}
                loggedAmarujala={loggedAmarujala}
                setLoggedAmarujala={setLoggedAmarujala}
                loggedBigfourth={loggedBigfourth}
                setLoggedBigfourth={setLoggedBigfourth}
                loggedUdyog={loggedUdyog}
                setLoggedUdyog={setLoggedUdyog}
              />
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
