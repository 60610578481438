import React, { useEffect, useState } from "react";
import {
  Stack,
  Card,
  Button,
  TextField,
  CardHeader,
  CardContent,
  Typography,
  Toolbar,
  CssBaseline,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Login(props) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    pass: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setLoginData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { email, pass } = loginData;

    if (
      email === `${process.env.REACT_APP_USER_AWT}` &&
      pass === `${process.env.REACT_APP_PASS_AWT}`
    ) {
      props.setLoggedAwt(true);
    } else if (
      email === `${process.env.REACT_APP_USER_QUIZADMIN}` &&
      pass === `${process.env.REACT_APP_PASS_QUIZADMIN}`
    ) {
      props.setLoggedQuizAdmin(true);
    } else if (
      email === `${process.env.REACT_APP_USER_FANDOM}` &&
      pass === `${process.env.REACT_APP_PASS_FANDOM}`
    ) {
      props.setLoggedFandom(true);
    } else if (
      email === `${process.env.REACT_APP_USER_PROPS}` &&
      pass === `${process.env.REACT_APP_PASS_PROPS}`
    ) {
      props.setLoggedProps(true);
    } else if (
      email === `${process.env.REACT_APP_USER_ZOOM}` &&
      pass === `${process.env.REACT_APP_PASS_ZOOM}`
    ) {
      props.setLoggedZoomBangla(true);
    } else if (
      email === `${process.env.REACT_APP_USER_SETN}` &&
      pass === `${process.env.REACT_APP_PASS_SETN}`
    ) {
      props.setLoggedSetn(true);
    } else if (
      email === `${process.env.REACT_APP_USER_TVSEASON}` &&
      pass === `${process.env.REACT_APP_PASS_TVSEASON}`
    ) {
      props.setLoggedTvseason(true);
    } else if (
      email === `${process.env.REACT_APP_USER_PINKVILLA}` &&
      pass === `${process.env.REACT_APP_PASS_PINKVILLA}`
    ) {
      props.setLoggedPinkvilla(true);
    } else if (
      email === `${process.env.REACT_APP_USER_SANDESH}` &&
      pass === `${process.env.REACT_APP_PASS_SANDESH}`
    ) {
      props.setLoggedSandesh(true);
    } else if (
      email === `${process.env.REACT_APP_USER_THINGSINT}` &&
      pass === `${process.env.REACT_APP_PASS_THINGSINT}`
    ) {
      props.setLoggedThingsint(true);
    } else if (
      email === `${process.env.REACT_APP_USER_COROVER}` &&
      pass === `${process.env.REACT_APP_PASS_COROVER}`
    ) {
      props.setLoggedCorover(true);
    } else if (
      email === `${process.env.REACT_APP_USER_DETIK}` &&
      pass === `${process.env.REACT_APP_PASS_DETIK}`
    ) {
      props.setLoggedDetik(true);
    } else if (
      email === `${process.env.REACT_APP_USER_INFOMO}` &&
      pass === `${process.env.REACT_APP_PASS_INFOMO}`
    ) {
      props.setLoggedInfomo(true);
    } else if (
      email === `${process.env.REACT_APP_USER_ADGEBRA}` &&
      pass === `${process.env.REACT_APP_PASS_ADGEBRA}`
    ) {
      props.setLoggedAdgebra(true);
    } else if (
      email === `${process.env.REACT_APP_USER_MOMJUNC}` &&
      pass === `${process.env.REACT_APP_PASS_MOMJUNC}`
    ) {
      props.setLoggedMomjunc(true);
    } else if (
      email === `${process.env.REACT_APP_USER_PLAYBUZZ}` &&
      pass === `${process.env.REACT_APP_PASS_PLAYBUZZ}`
    ) {
      props.setLoggedPlaybuzz(true);
    } else if (
      email === `${process.env.REACT_APP_USER_ALLPLAYNEWS}` &&
      pass === `${process.env.REACT_APP_PASS_ALLPLAYNEWS}`
    ) {
      props.setLoggedAllplaynews(true);
    } else if (
      email === `${process.env.REACT_APP_USER_JIOCINEMA}` &&
      pass === `${process.env.REACT_APP_PASS_JIOCINEMA}`
    ) {
      props.setLoggedJiocinema(true);
    } else if (
      email === `${process.env.REACT_APP_USER_MONETISEUP}` &&
      pass === `${process.env.REACT_APP_PASS_MONETISEUP}`
    ) {
      props.setLoggedMonetiseup(true);
    } else if (
      email === `${process.env.REACT_APP_USER_VUVU}` &&
      pass === `${process.env.REACT_APP_PASS_VUVU}`
    ) {
      props.setLoggedVuvu(true);
    } else if (
      email === `${process.env.REACT_APP_USER_REDIADS}` &&
      pass === `${process.env.REACT_APP_PASS_REDIADS}`
    ) {
      props.setLoggedRediads(true);
    } else if (
      email === `${process.env.REACT_APP_USER_OPSADMIN}` &&
      pass === `${process.env.REACT_APP_PASS_OPSADMIN}`
    ) {
      props.setOpsAdmin(true);
    } else if (
      email === `${process.env.REACT_APP_USER_ALLPLAYGAM}` &&
      pass === `${process.env.REACT_APP_PASS_ALLPLAYGAM}`
    ) {
      props.setLoggedAllplaynewsGAM(true);
    } else if (
      email === `${process.env.REACT_APP_USER_INFINITEDEV}` &&
      pass === `${process.env.REACT_APP_PASS_INFINITEDEV}`
    ) {
      props.setLoggedInfiniteDev(true);
    } else if (
      email === `${process.env.REACT_APP_USER_GETCHANNELS}` &&
      pass === `${process.env.REACT_APP_PASS_GETCHANNELS}`
    ) {
      props.setLoggedGetchannels(true);
    } else if (
      email === `${process.env.REACT_APP_USER_DEFAULTNEW}` &&
      pass === `${process.env.REACT_APP_PASS_DEFAULTNEW}`
    ) {
      props.setLoggedDefaultNew(true);
    } else if (
      email === `${process.env.REACT_APP_USER_AMARUJALA}` &&
      pass === `${process.env.REACT_APP_PASS_AMARUJALA}`
    ) {
      props.setLoggedAmarujala(true);
    } else if (
      email === `${process.env.REACT_APP_USER_BIGFOURTH}` &&
      pass === `${process.env.REACT_APP_PASS_BIGFOURTH}`
    ) {
      props.setLoggedBigfourth(true);
    } else if (
      email === `${process.env.REACT_APP_USER_UDYOG}` &&
      pass === `${process.env.REACT_APP_PASS_UDYOG}`
    ) {
      props.setLoggedUdyog(true);
    } else {
      alert("Invalid credentials");
    }

    // event.preventDefault();
  };

  useEffect(() => {
    if (props.loggedQuizAdmin) navigate("/quiztwizadmin");
    else if (props.loggedAwt) navigate("/allwomenstalk");
    else if (props.loggedProps) navigate("/props");
    else if (props.loggedFandom) navigate("/fandom");
    else if (props.loggedZoomBangla) navigate("/zoombangla");
    else if (props.loggedTvseason) navigate("/tvseasonspoilers");
    else if (props.loggedPinkvilla) navigate("/pinkvilla");
    else if (props.loggedSandesh) navigate("/sandesh");
    else if (props.loggedThingsint) navigate("/thingsinteractive");
    else if (props.loggedCorover) navigate("/corover");
    else if (props.loggedDetik) navigate("/detik");
    else if (props.loggedSetn) navigate("/setn");
    else if (props.loggedInfomo) navigate("/infomo");
    else if (props.loggedAdgebra) navigate("/adgebra");
    else if (props.loggedMomjunc) navigate("/momjunction");
    else if (props.loggedPlaybuzz) navigate("/playbuzz");
    else if (props.loggedAllplaynews) navigate("/allplaynews");
    else if (props.loggedJiocinema) navigate("/jiocinema");
    else if (props.loggedMonetiseup) navigate("/monetiseup");
    else if (props.loggedVuvu) navigate("/vuvutv");
    else if (props.loggedRediads) navigate("/rediads");
    else if (props.opsAdmin) navigate("/opsform");
    else if (props.loggedAllplaynewsGAM) navigate("/allplaynews_adx");
    else if (props.loggedInfiniteDev) navigate("/infinitedeveloper");
    else if (props.loggedGetchannels) navigate("/getchannels");
    else if (props.loggedDefaultNew) navigate("/defaultnew");
    else if (props.loggedAmarujala) navigate("/amarujala");
    else if (props.loggedBigfourth) navigate("/bigfourth");
    else if (props.loggedUdyog) navigate("/udyogmitra");
  }, [
    navigate,
    props.loggedQuizAdmin,
    props.loggedAwt,
    props.loggedProps,
    props.loggedFandom,
    props.loggedZoomBangla,
    props.loggedSetn,
    props.loggedTvseason,
    props.loggedPinkvilla,
    props.loggedSandesh,
    props.loggedThingsint,
    props.loggedDetik,
    props.loggedCorover,
    props.loggedInfomo,
    props.loggedAdgebra,
    props.loggedMomjunc,
    props.loggedPlaybuzz,
    props.loggedAllplaynews,
    props.loggedJiocinema,
    props.loggedMonetiseup,
    props.loggedVuvu,
    props.loggedRediads,
    props.opsAdmin,
    props.loggedAllplaynewsGAM,
    props.loggedInfiniteDev,
    props.loggedGetchannels,
    props.loggedDefaultNew,
    props.loggedAmarujala,
    props.loggedBigfourth,
    props.loggedUdyog,
  ]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Unibots
          </Typography>
        </Toolbar>
      </AppBar>
      <Card
        sx={{
          width: "370px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10%",
        }}
        raised
      >
        <CardHeader style={{ fontSize: "10px" }} title="Login" />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              margin={2}
            >
              <TextField
                onChange={handleChange}
                name="email"
                value={loginData.email}
                label="User"
              />
            </Stack>{" "}
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              margin={2}
            >
              <TextField
                onChange={handleChange}
                name="pass"
                value={loginData.password}
                label="Password"
                type="password"
              />
            </Stack>
            <Stack spacing={3} direction="row" justifyContent="center" mt={3}>
              <Button variant="contained" type="submit">
                Login
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Login;
