import React, { useEffect, useState } from "react";
import axios from "axios";
import { parse, stringify } from "qs";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  CircularProgress,
  Backdrop,
  ButtonGroup,
  Chip,
} from "@mui/material";
import { Table } from "antd";
import dayjs from "dayjs";
import { DatePicker, Space, Typography } from "antd";
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend } from "recharts";
import "../index.css";

const { RangePicker } = DatePicker;

const axiosInstance = axios.create({
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
});

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return [dd, mm, yyyy];
};

const flattenData = (data) => {
  const result = [];
  const flatten = (record, parentKey) => {
    const { key, ...rest } = record;
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    result.push({
      key: currentKey,
      Date: rest.date,
      "Page Views": rest.pv,
      "Total Impression": rest.total_imp,
      "Total Revenue": rest.total_rev,
      "Average ECPM": rest.avg_ecpm,
      RPM: rest.rpm,
      "Fill Rate": rest.fillrate,
    });
  };
  data.forEach((record) => flatten(record));
  return result;
};

function Playbuzz(props) {
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);
  const navigate = useNavigate();

  const [dateValue, setDateValue] = useState([null, null]);
  const [totalPv, setTotalPv] = useState("");
  const [totalRev, setTotalRev] = useState("");
  const [averageRpm, setAverageRpm] = useState("");
  const [pvGraph, setPvGraph] = useState([]);
  const [revGraph, setRevGraph] = useState([]);
  const [rpmGraph, setRpmGraph] = useState([]);
  const [dateRangeVal, setDateRangeVal] = useState([null, null]);
  const [quickDate, setQuickDate] = useState("");

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      // width: 150,
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: "15vw",
    },
    {
      title: "Estimated Earnings",
      dataIndex: "rev",
      key: "rev",
      sorter: (a, b) => a.rev - b.rev,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.rev) ? 0 : "$" + Number(record.rev).toFixed(2);
      },
      // width: 150
    },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
      sorter: (a, b) => a.pv - b.pv,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.pv) ? "NA" : record.pv;
      },
      // width: 150
    },
    {
      title: "Page RPM",
      dataIndex: "pv_rpm",
      key: "pv_rpm",
      sorter: (a, b) => a.pv_rpm - b.pv_rpm,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Impressions",
      dataIndex: "imp",
      key: "imp",
    },
    {
      title: "Impression RPM",
      dataIndex: "imp_rpm",
      key: "imp_rpm",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
  ];

  useEffect(() => {
    if (!props.loggedPlaybuzz) navigate("/login");

    var today = new Date();
    // var today = new Date('2023','01','31');
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1, 9);
    const obj2 = { $d: today };
    const obj1 = { $d: firstDay };
    var d = createDate(today);
    const d1 = d[2] + "-" + d[1] + "-" + d[0];
    d = createDate(firstDay);
    const d2 = d[2] + "-" + d[1] + "-" + d[0];
    // console.log(firstDay, today);
    setDateRangeVal([d2, d1]);
    setDateValue([obj1, obj2]);
    setSearchDate(!searchDate);
  }, []);

  useEffect(() => {
    if (dateValue[0] != null && dateValue[1] != null && searchDate !== 0) {
      const getData = async () => {
        setLoadingScreen(true);
        setTableData([]);

        const res = await axios.post(
          `${process.env.REACT_APP_ADSENSE_URL}/get_quiztwiz_sitedata`,
          {
            data: {
              dateStart: dateValue[0].$d.toISOString().split("T")[0],
              dateEnd: dateValue[1].$d.toISOString().split("T")[0],
              siteName: "playbuzz",
              countryName: ["All Countries"],
              geodata: false,
            },
          }
        );
        res.data = res.data.response;
        // console.log(res.data);
        setTableData(res.data.data);
        setTotalPv(res.data.pv);
        setTotalRev(res.data.rev);
        setAverageRpm(res.data.pv_rpm);
        setPvGraph(res.data.pvGraph);
        setRevGraph(res.data.revGraph);
        setRpmGraph(res.data.pv_rpmGraph);

        setLoadingScreen(false);
      };
      getData();
    }
  }, [searchDate]);

  // const flattenedData = flattenData(tableData).map((record) => {
  //   delete record.key;
  //   delete record.name;
  //   return record;
  // });

  return (
    <Box component="main" sx={{ flexGrow: 1, px: 8 }}>
      {/* to keep h4 below appbar */}
      {/* <DrawerHeader />  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <svg
            width="200"
            height="100"
            viewBox="0 0 1352 186"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 144.371V56.0952L21.633 42.3667V133.595L70.9326 159.724V186L0 144.371Z"
              fill="black"
            />
            <path
              d="M166 144.371V56.0952L144.367 42.3667V133.595L95.0674 159.724V186L166 144.371Z"
              fill="black"
            />
            <path
              d="M127.738 51.3714L144.514 42.3667V69.8238L116.112 88.7191L127.738 51.3714Z"
              fill="black"
            />
            <path
              d="M70.9326 159.724V32.0789V7.52857L49.8883 19.781V133.595L70.9326 159.724Z"
              fill="black"
            />
            <path
              d="M95.0674 159.724V32.0789V7.52857L116.112 19.781V133.595L95.0674 159.724Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M116.112 19.781L95.0674 7.52857V32.0789L116.112 44.2577V19.781ZM70.9326 32.0789V7.52857L49.8883 19.781V44.2577L70.9326 32.0789Z"
              fill="black"
            />
            <path
              d="M414 56H396V129.8H306V56H288V129.8C288 129.8 288 146 304.2 146H397.8C414.54 146 414 129.8 414 129.8V56Z"
              fill="black"
            />
            <path
              d="M468.07 56V146H486.07V77.6L567.07 146H585.07V56H567.07V124.4L486.07 56H468.07Z"
              fill="black"
            />
            <path d="M657 56H639V146H657V56Z" fill="black" />
            <path
              d="M710.965 56V146H820.765C836.965 146 836.965 129.8 836.965 129.8V106.4C836.965 101 831.565 101 831.565 101C836.965 101 836.965 95.6 836.965 95.6V72.2C836.965 56 820.765 56 820.765 56H710.965ZM818.965 92H728.965V72.2H818.965V92ZM818.965 129.8H728.965V108.2H818.965V129.8Z"
              fill="black"
            />
            <path
              d="M999.035 56H909.035C891.035 56 891.035 72.2 891.035 72.2V129.8C891.035 129.8 891.035 146 909.035 146H999.035C1017.04 146 1017.04 129.8 1017.04 129.8V72.2C1017.04 72.2 1017.04 56 999.035 56ZM999.035 72.2V129.8H909.035V72.2H999.035Z"
              fill="black"
            />
            <path
              d="M1184.51 56H1058.51V72.2H1112.51V146H1130.51V72.2H1184.51V56Z"
              fill="black"
            />
            <path
              d="M1333.86 92H1243.86V72.2H1350.06V56H1243.86C1225.86 56 1225.86 72.2 1225.86 72.2V92C1225.86 92 1225.86 108.2 1243.86 108.2H1333.86V129.8H1225.86V146H1333.86C1351.86 146 1351.86 129.8 1351.86 129.8V108.2C1351.86 108.2 1351.86 92 1333.86 92Z"
              fill="black"
            />
          </svg>

          <Box
            display="flex"
            // justifyContent="end"
            alignItems="center"
            sx={{ mb: 3, mt: 3 }}
          >
            <Space direction="vertical" size={12}>
              <RangePicker
                value={
                  dateRangeVal[0] !== "" && dateRangeVal[1] !== ""
                    ? [dayjs(dateRangeVal[0]), dayjs(dateRangeVal[1])]
                    : undefined
                }
                // size="large"
                onChange={(date, dateString) => {
                  var ourdate = [
                    dayjs(`${dateString[0]} 09:00`),
                    dayjs(`${dateString[1]} 09:00`),
                  ];
                  setQuickDate("");
                  console.log("quick date set");
                  // setDateRangeVal([null,null])
                  setDateValue(ourdate);
                  setDateRangeVal(dateString);
                  // console.log(dateString)
                }}
              />
            </Space>
            <Button
              sx={{ mx: 2 }}
              variant="contained"
              size="small"
              onClick={() => setSearchDate(searchDate + 1)}
            >
              Search
            </Button>
          </Box>

          <ButtonGroup
            size="small"
            variant="outlined"
            color="primary"
            sx={{ py: 4 }}
          >
            <Button
              onClick={() => {
                var today = new Date();
                const d = createDate(today);
                // today.setDate(today.getDate()-2)
                const d1 = d[2] + "-" + d[1] + "-" + d[0];
                const obj = { $d: today };
                setDateValue([obj, obj]);
                setDateRangeVal([d1, d1]);
                setSearchDate(!searchDate);
                setQuickDate("24H");
              }}
              variant={quickDate === "24H" ? "contained" : "outlined"}
            >
              24H
            </Button>

            <Button
              onClick={() => {
                var today = new Date();
                const d1 = createDate(today);
                // today.setDate(today.getDate()-2)
                var thatDay = new Date();
                thatDay.setDate(thatDay.getDate() - 6);
                const d2 = createDate(thatDay);
                // thatDay.setDate(thatDay.getDate()-2)

                const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
                const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

                const obj2 = { $d: today };
                const obj1 = { $d: thatDay };
                // console.log([obj1,obj2])
                setDateValue([obj1, obj2]);
                setDateRangeVal([date1, date2]);
                setSearchDate(!searchDate);
                setQuickDate("7D");
              }}
              variant={quickDate === "7D" ? "contained" : "outlined"}
            >
              7D
            </Button>

            <Button
              onClick={() => {
                var today = new Date();
                const d1 = createDate(today);
                // today.setDate(today.getDate()-2)
                var thatDay = new Date();
                thatDay.setDate(thatDay.getDate() - 30);
                const d2 = createDate(thatDay);
                // thatDay.setDate(thatDay.getDate()-2)

                const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
                const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

                const obj2 = { $d: today };
                const obj1 = { $d: thatDay };
                // console.log([obj1,obj2])
                setDateValue([obj1, obj2]);
                setDateRangeVal([date1, date2]);
                setSearchDate(!searchDate);
                setQuickDate("30D");
              }}
              variant={quickDate === "30D" ? "contained" : "outlined"}
            >
              30D
            </Button>
          </ButtonGroup>
        </Box>
        <Box>
          <Box
          // sx={modalOpen?{filter:'blur(10px)'}:null}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignContent="center"
              sx={{ my: 4 }}
            >
              <Box display="block" justifyContent="space-between"></Box>
              <Box display="block">
                <AreaChart
                  width={350}
                  height={250}
                  data={pvGraph}
                  margin={{ top: 10, bottom: 10, right: 5, left: -5 }}
                >
                  <defs>
                    <linearGradient id="colorImp" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2e7d32" stopOpacity={0.4} />
                      <stop offset="95%" stopColor="#2e7d32" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend margin={{ right: 1 }} />
                  <Area
                    type="monotone"
                    dataKey="pv"
                    stroke="#2e7d32"
                    fillOpacity={1}
                    fill="url(#colorImp)"
                  />
                </AreaChart>
                <Box display="flex" justifyContent="center" sx={{ ml: 6 }}>
                  <Chip
                    sx={{ p: 1, mt: 6 }}
                    size="large"
                    label={`Page Views: ${totalPv}`}
                    variant="outlined"
                    color="success"
                  />
                </Box>
              </Box>

              <Box>
                <AreaChart
                  width={350}
                  height={250}
                  data={revGraph}
                  margin={{ top: 10, bottom: 10, right: 5, left: -15 }}
                >
                  <defs>
                    <linearGradient id="colorRev" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#1976d2" stopOpacity={0.4} />
                      <stop offset="95%" stopColor="#1976d2" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" style={{ marginLeft: "50px" }} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="Earning"
                    stroke="#1976d2"
                    fillOpacity={1}
                    fill="url(#colorRev)"
                  />
                </AreaChart>
                <Box display="flex" justifyContent="center" sx={{ ml: 6 }}>
                  <Chip
                    sx={{ p: 1, mt: 6 }}
                    label={`Total Rev: $${totalRev}`}
                    variant="outlined"
                    color="primary"
                  />
                </Box>
              </Box>

              <Box>
                <AreaChart
                  width={350}
                  height={250}
                  data={rpmGraph}
                  margin={{ top: 10, bottom: 10, right: 5, left: -20 }}
                >
                  <defs>
                    <linearGradient id="colorRpm" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#e83462" stopOpacity={0.4} />
                      <stop offset="95%" stopColor="#e83462" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="pv_rpm"
                    stroke="#e83462"
                    fillOpacity={1}
                    fill="url(#colorRpm)"
                  />
                </AreaChart>
                <Box display="flex" justifyContent="center" sx={{ ml: 4 }}>
                  <Chip
                    sx={{ p: 1, mt: 6 }}
                    label={`Page Views RPM: $${averageRpm}`}
                    variant="outlined"
                    color="error"
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Table
                bordered
                columns={columns}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
                dataSource={tableData}
                scroll={{
                  x: 1000,
                  y: 800,
                }}
              />
            </Box>
            <Box
              display="block"
              justifyContent="space-between"
              alignContent="center"
              sx={{ my: 2 }}
            >
              <Typography variant="caption">
                *NA entries will be updated within 24 hours
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Playbuzz;
