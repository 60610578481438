import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Backdrop, Button } from "@mui/material";
import { Table, Switch, Typography } from "antd";
import { CSVLink } from "react-csv";

const { Text } = Typography;

function Getchannels(props) {
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [accountToggle, setAccountToggle] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [inactiveFlag, setInactiveFlag] = useState(false);

  const navigate = useNavigate();

  let columns = [
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
      width: 200,
    },
    {
      title: "Channel Name",
      dataIndex: "channelName",
      key: "channelName",
      width: 200,
    },
    {
      title: "Channel ID",
      dataIndex: "channelId",
      key: "channelId",
      width: 160,
    },
  ];

  const getChannelData = async () => {
    setLoadingScreen(true);
    console.log("accountToggle: ", accountToggle ? "POA" : "SIP");
    const res = await axios.post(
      `${process.env.REACT_APP_ADSENSE_URL}/channels_data/fetch`,
      {
        data: {
          accountName: accountToggle ? "POA" : "SIP",
          inactive: inactiveFlag,
        },
      },
      {
        headers: {
          AuthKey: `${process.env.REACT_APP_GETCHANNELS_TOKEN}`,
        },
      }
    );
    setTableData(res.data);
    setLoadingScreen(false);
  };

  useEffect(() => {
    if (!props.loggedGetchannels) navigate("/login");

    getChannelData();
  }, [accountToggle, inactiveFlag]);

  const flattenData = (data) => {
    const result = [];
    const flatten = (record) => {
      const { key, ...rest } = record;
      console.log(key);
      result.push({
        key: key,
        // Date: rest.date,
        "Account ID": rest.accountId,
        "Channel Name": rest.channelName,
        "Channel ID": rest.channelId,
      });
    };
    data.forEach((record) => flatten(record));
    return result;
  };

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    return record;
  });

  return (
    <div className="w-full">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="flex justify-between">
        <div className="my-4 px-2">
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{ textDecoration: "none" }} // Remove underline from text
          >
            <CSVLink
              filename={"DataTable.csv"}
              data={flattenedData}
              className="btn btn-primary"
              style={{ textDecoration: "none" }} // Remove underline from text
            >
              <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                <span style={{ color: "#FFFFFF" }}>Export to CSV</span>
              </Typography>
            </CSVLink>
          </Button>
        </div>
        <div className="flex space-x-2 justify-end my-4 px-2">
          <Switch
            checked={!inactiveFlag}
            onChange={() => setInactiveFlag(!inactiveFlag)}
          />
          <Text strong>{inactiveFlag ? "Inactive" : "AllChannels"}</Text>
          <Switch
            checked={!accountToggle}
            onChange={() => setAccountToggle(!accountToggle)}
          />
          <Text strong>{accountToggle ? "POA" : "SIP"}</Text>
        </div>
      </div>

      <div className="flex">
        <Table
          bordered
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
          }}
          dataSource={tableData}
          scroll={{
            x: 1000,
            y: 800,
          }}
        />
      </div>
    </div>
  );
}

export default Getchannels;
