import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  Button,
  Box,
  CircularProgress,
  Backdrop,
  ButtonGroup,
  Chip,
} from "@mui/material";
import { Select, Table } from "antd";
import dayjs from "dayjs";
import { DatePicker, Space, Typography } from "antd";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "../index.css";
import coroverLogo from "../images/corover.png";

const { RangePicker } = DatePicker;

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return [dd, mm, yyyy];
};

const flattenData = (data) => {
  const result = [];
  const flatten = (record, parentKey) => {
    const { key, ...rest } = record;
    const currentKey = parentKey ? `${parentKey}.${key}` : key;
    result.push({
      key: currentKey,
      Date: rest.date,
      "Total Impression": rest.total_imp,
      "Total Revenue": rest.total_rev,
      "Average ECPM": rest.avg_ecpm,
    });
  };
  data.forEach((record) => flatten(record));
  return result;
};

function Corover(props) {
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);
  const navigate = useNavigate();

  const [dateValue, setDateValue] = useState([null, null]);
  const [totalImp, setTotalImp] = useState("");
  const [totalRev, setTotalRev] = useState("");
  const [AverageRpm, setAverageRpm] = useState("");
  const [impGraph, setImpGraph] = useState([]);
  const [revGraph, setRevGraph] = useState([]);
  const [rpmGraph, setRPMGraph] = useState([]);
  const [dateRangeVal, setDateRangeVal] = useState([null, null]);
  const [quickDate, setQuickDate] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("All Devices");
  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  const [selectedSite, setSelectedSite] = useState([]);
  const [filterSubmit, setFilterSubmit] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [adOptions, setAdOptions] = useState({});
  const [selectAdOptions, setSelectAdOptions] = useState([]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Impressions",
      dataIndex: "total_imp",
      key: "imp",
    },
    {
      title: "Gross Revenue",
      dataIndex: "total_rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + Number(record.total_rev).toFixed(2);
      },
    },
    {
      title: "Average ECPM",
      dataIndex: "avg_ecpm",
      key: "ecpm",
      render: (_, record) => {
        return isNaN(record.avg_ecpm)
          ? "NA"
          : "$" + Number(record.avg_ecpm).toFixed(2);
      },
    },
    {
      title: "Total CTR",
      dataIndex: "total_CTR",
      key: "total_CTR",
      render: (_, record) => {
        return record.total_CTR + "%";
      },
    },
  ];

  useEffect(() => {
    if (!props.loggedCorover) navigate("/login");

    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1, 9);
    const obj2 = { $d: today };
    const obj1 = { $d: firstDay };
    var d = createDate(today);
    const d1 = d[2] + "-" + d[1] + "-" + d[0];
    d = createDate(firstDay);
    const d2 = d[2] + "-" + d[1] + "-" + d[0];
    setDateRangeVal([d2, d1]);
    setDateValue([obj1, obj2]);
    setSearchDate(!searchDate);

    const getData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_GAMDASH_CDN}/corover.json`
      );
      setAdOptions(res.data.adOptions);
      setSelectAdOptions(res.data.selectAdOptions);
    };
    getData();
  }, []);

  useEffect(() => {
    if (dateValue[0] != null && dateValue[1] != null && searchDate !== 0) {
      const getData = async () => {
        setLoadingScreen(true);
        setTableData([]);

        const adMain = [];
        for (let key in adOptions) {
          if (selectedSite.includes(key))
            adMain.push(adOptions[key].adUnitIDMain);
        }

        const res = await axios.post(
          `${process.env.REACT_APP_CLIENTDASH_URL}/client/device_country_DB`,
          {
            data: {
              dateStart: dateValue[0].$d.toISOString().split("T")[0],
              dateEnd: dateValue[1].$d.toISOString().split("T")[0],
              deviceType: "All Devices",
              countryName: "All Countries",
              adUnitIDMain:
                selectedSite.length === 0
                  ? adOptions["All Units"].adUnitIDMain
                  : adMain,
              adUnitIDNewMain: [""],
              GaPropertyId: [""],
              children: "false",
            },
          },
          {
            headers: {
              // "x-api-key": process.env.REACT_APP_API_KEY,
              Authorization: process.env.REACT_APP_GLOBAL_TOKEN,
            },
          }
        );
        setTableData(res.data.data);
        setTotalImp(res.data.imp);
        setTotalRev(res.data.rev);
        setAverageRpm(res.data.rpm);
        setImpGraph(res.data.impGraph);
        setRevGraph(res.data.revGraph);
        setRPMGraph(res.data.rpmGraph);

        const flattenedData = flattenData(tableData).map((record) => {
          return record;
        });
        setCsvData(flattenedData);
        setLoadingScreen(false);
      };
      if (Object.keys(adOptions).length !== 0) getData();
    }
  }, [searchDate, selectedDevice, selectedCountry, filterSubmit, adOptions]);

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    delete record.name;
    return record;
  });

  return (
    <Box component="main" sx={{ flexGrow: 1, px: 8 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <svg
            width="200"
            height="100"
            viewBox="0 0 1352 186"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 144.371V56.0952L21.633 42.3667V133.595L70.9326 159.724V186L0 144.371Z"
              fill="black"
            />
            <path
              d="M166 144.371V56.0952L144.367 42.3667V133.595L95.0674 159.724V186L166 144.371Z"
              fill="black"
            />
            <path
              d="M127.738 51.3714L144.514 42.3667V69.8238L116.112 88.7191L127.738 51.3714Z"
              fill="black"
            />
            <path
              d="M70.9326 159.724V32.0789V7.52857L49.8883 19.781V133.595L70.9326 159.724Z"
              fill="black"
            />
            <path
              d="M95.0674 159.724V32.0789V7.52857L116.112 19.781V133.595L95.0674 159.724Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M116.112 19.781L95.0674 7.52857V32.0789L116.112 44.2577V19.781ZM70.9326 32.0789V7.52857L49.8883 19.781V44.2577L70.9326 32.0789Z"
              fill="black"
            />
            <path
              d="M414 56H396V129.8H306V56H288V129.8C288 129.8 288 146 304.2 146H397.8C414.54 146 414 129.8 414 129.8V56Z"
              fill="black"
            />
            <path
              d="M468.07 56V146H486.07V77.6L567.07 146H585.07V56H567.07V124.4L486.07 56H468.07Z"
              fill="black"
            />
            <path d="M657 56H639V146H657V56Z" fill="black" />
            <path
              d="M710.965 56V146H820.765C836.965 146 836.965 129.8 836.965 129.8V106.4C836.965 101 831.565 101 831.565 101C836.965 101 836.965 95.6 836.965 95.6V72.2C836.965 56 820.765 56 820.765 56H710.965ZM818.965 92H728.965V72.2H818.965V92ZM818.965 129.8H728.965V108.2H818.965V129.8Z"
              fill="black"
            />
            <path
              d="M999.035 56H909.035C891.035 56 891.035 72.2 891.035 72.2V129.8C891.035 129.8 891.035 146 909.035 146H999.035C1017.04 146 1017.04 129.8 1017.04 129.8V72.2C1017.04 72.2 1017.04 56 999.035 56ZM999.035 72.2V129.8H909.035V72.2H999.035Z"
              fill="black"
            />
            <path
              d="M1184.51 56H1058.51V72.2H1112.51V146H1130.51V72.2H1184.51V56Z"
              fill="black"
            />
            <path
              d="M1333.86 92H1243.86V72.2H1350.06V56H1243.86C1225.86 56 1225.86 72.2 1225.86 72.2V92C1225.86 92 1225.86 108.2 1243.86 108.2H1333.86V129.8H1225.86V146H1333.86C1351.86 146 1351.86 129.8 1351.86 129.8V108.2C1351.86 108.2 1351.86 92 1333.86 92Z"
              fill="black"
            />
          </svg>

          <Box display="flex" alignItems="center" sx={{ mb: 3, mt: 3 }}>
            <Space direction="vertical" size={12}>
              <RangePicker
                value={
                  dateRangeVal[0] !== "" && dateRangeVal[1] !== ""
                    ? [dayjs(dateRangeVal[0]), dayjs(dateRangeVal[1])]
                    : undefined
                }
                onChange={(date, dateString) => {
                  var ourdate = [
                    dayjs(`${dateString[0]} 09:00`),
                    dayjs(`${dateString[1]} 09:00`),
                  ];
                  setQuickDate("");
                  setDateValue(ourdate);
                  setDateRangeVal(dateString);
                }}
              />
            </Space>
            <Button
              sx={{ mx: 2 }}
              variant="contained"
              size="small"
              onClick={() => setSearchDate(searchDate + 1)}
            >
              Search
            </Button>
          </Box>

          <ButtonGroup
            size="small"
            variant="outlined"
            color="primary"
            sx={{ py: 4 }}
          >
            <Button
              onClick={() => {
                var today = new Date();
                const d = createDate(today);
                const d1 = d[2] + "-" + d[1] + "-" + d[0];
                const obj = { $d: today };
                setDateValue([obj, obj]);
                setDateRangeVal([d1, d1]);
                setSearchDate(!searchDate);
                setQuickDate("24H");
              }}
              variant={quickDate === "24H" ? "contained" : "outlined"}
            >
              24H
            </Button>

            <Button
              onClick={() => {
                var today = new Date();
                const d1 = createDate(today);
                var thatDay = new Date();
                thatDay.setDate(thatDay.getDate() - 6);
                const d2 = createDate(thatDay);

                const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
                const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

                const obj2 = { $d: today };
                const obj1 = { $d: thatDay };
                setDateValue([obj1, obj2]);
                setDateRangeVal([date1, date2]);
                setSearchDate(!searchDate);
                setQuickDate("7D");
              }}
              variant={quickDate === "7D" ? "contained" : "outlined"}
            >
              7D
            </Button>

            <Button
              onClick={() => {
                var today = new Date();
                const d1 = createDate(today);
                var thatDay = new Date();
                thatDay.setDate(thatDay.getDate() - 30);
                const d2 = createDate(thatDay);

                const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
                const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

                const obj2 = { $d: today };
                const obj1 = { $d: thatDay };
                setDateValue([obj1, obj2]);
                setDateRangeVal([date1, date2]);
                setSearchDate(!searchDate);
                setQuickDate("30D");
              }}
              variant={quickDate === "30D" ? "contained" : "outlined"}
            >
              30D
            </Button>
          </ButtonGroup>
          <img width="auto" height="30" src={coroverLogo} alt="Props logo" />
        </Box>
        <Box>
          <Box>
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-between items-center my-4 h-auto lg:h-72">
              <div className="block align-top justify-center pb-12">
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Select AdUnits"
                  style={{
                    width: "80%",
                    maxWidth: 420,
                    maxHeight: 120,
                    overflowY: "auto",
                  }}
                  options={selectAdOptions}
                  value={selectedSite}
                  onChange={(val) => {
                    setSelectedSite(val);
                  }}
                ></Select>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ my: 1 }}
                    onClick={() => setFilterSubmit(!filterSubmit)}
                  >
                    Apply
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ textDecoration: "none", mt: 2 }} // Remove underline from text
                  >
                    <CSVLink
                      filename={"DataTable.csv"}
                      data={flattenedData}
                      className="btn btn-primary"
                      style={{ textDecoration: "none" }} // Remove underline from text
                    >
                      <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                        <span style={{ color: "#FFFFFF" }}>Export to CSV</span>
                      </Typography>
                    </CSVLink>
                  </Button>
                </div>
              </div>
              <div className="block h-64 lg:h-full w-full">
                <div className="flex justify-center h-5/6 w-full">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      data={impGraph}
                      margin={{ top: 10, bottom: 10, right: 20 }}
                    >
                      <defs>
                        <linearGradient
                          id="colorImp"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#2e7d32"
                            stopOpacity={0.4}
                          />
                          <stop
                            offset="95%"
                            stopColor="#2e7d32"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="Impressions"
                        stroke="#2e7d32"
                        fillOpacity={1}
                        fill="url(#colorImp)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className="flex justify-center">
                  <Chip
                    sx={{ p: 1 }}
                    size="large"
                    label={`Impressions: ${totalImp}`}
                    variant="outlined"
                    color="success"
                  />
                </div>
              </div>

              <div className="block h-64 lg:h-full w-full">
                {/* <Box> */}
                <div className="flex justify-center h-5/6 w-full">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      data={revGraph}
                      margin={{ top: 10, bottom: 10, right: 20 }}
                    >
                      <defs>
                        <linearGradient
                          id="colorRev"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#1976d2"
                            stopOpacity={0.4}
                          />
                          <stop
                            offset="95%"
                            stopColor="#1976d2"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>

                      <XAxis dataKey="name" style={{ marginLeft: "50px" }} />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="Revenue"
                        stroke="#1976d2"
                        fillOpacity={1}
                        fill="url(#colorRev)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className="flex justify-center">
                  <Chip
                    sx={{ p: 1 }}
                    label={`Total Rev: $${totalRev}`}
                    variant="outlined"
                    color="primary"
                  />
                </div>
              </div>
            </div>
            <Box>
              <Table
                bordered
                columns={columns}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
                dataSource={tableData}
                scroll={{
                  x: 1000,
                  y: 800,
                }}
              />
            </Box>
            <Box
              display="block"
              justifyContent="space-between"
              alignContent="center"
              sx={{ my: 2 }}
            >
              <Typography variant="caption">
                *NA entries will be updated within 36 hours
              </Typography>
              <Typography variant="caption">
                ** GAM reports will be considered in case of any discrepancies
                in the data
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Corover;
